.isolate-summary {
  visibility: hidden;
  position: absolute;
  z-index: 2;
}
.isolate-summary p {
  margin-top: .75rem;
  line-height: .25;
}
.isolate-link:hover + .isolate-summary {
  pointer-events: none;
  visibility: visible;
  background-color: #f2f2f2;
  border-radius: 15px;
  border: 2px solid;
  padding: .5rem;
}
