.container {
  max-width: 100%;
}

.App {
  text-align: center;
  width: 100%;
  height: calc(100% - 70px);
  position: absolute;
}

header {
  padding-top: 0;
  min-height: 70px;
}

.max-w-readable {
  max-width: 60rem;
}

#small-font {
  font-size: 1.3vh;
}

#medium-font {
  font-size: 1.43vh;
}

#mediumLarge-font {
  font-size: 1.64vh;
}

#large-font {
  font-size: 2.05vh;
}

#header-font {
  font-size: 2.66vh;
}